<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import { formatDate } from "~/lib/date-time";
import { isTypeArticle, isTypePage, isTypeReport, type TypeArticle } from "~/types/contentful";

const props = defineProps<{
  article: TypeArticle<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const event = computed(() => props.article.fields.event);

const authors = computed(() =>
  props.article.fields.authors?.filter((author): author is NonNullable<typeof author> => !!author),
);

const themeOrTopics = computed(() =>
  props.article.fields.themeOrTopic?.filter(
    (themeOrTopic): themeOrTopic is NonNullable<typeof themeOrTopic> => !!themeOrTopic,
  ),
);
</script>

<template>
  <div
    class="article"
    :class="event && 'article--event'"
  >
    <HeaderVideoInline
      v-if="article.fields.videoDownload"
      :download="article.fields.videoDownload"
      :title="article.fields.title"
      :subtitle="article.fields.subtitle"
    />

    <UiContainer
      v-else-if="article.fields.title"
      class="relative bg-level-standard lg:border-l-[16px] lg:border-l-dark-blue-grey-two lg:before:absolute lg:before:left-2 lg:before:top-0 lg:before:block lg:before:h-full lg:before:w-1 lg:before:bg-dark-blue-grey-two lg:before:content-['']"
    >
      <HeaderBlockBreadcrumbs class="absolute mt-3 text-dark-blue" />

      <header class="grid grid-cols-12 gap-4">
        <div class="col-span-12 items-center pt-3 md:col-span-8 lg:col-span-8 lg:flex">
          <div class="header-text py-4 lg:py-5">
            <h1>{{ article.fields.title }}</h1>
            <div class="h2 mt-3 md:mt-4">
              {{ article.fields.subtitle }}
            </div>
            <div class="mt-4">
              <UiSaveButtonPrimary
                :entry="article"
                type="save"
              />
            </div>
          </div>
        </div>

        <div class="col-span-12 -ms-3 md:col-span-4 md:ms-0 lg:col-span-4">
          <NuxtImg
            v-if="article.fields.image?.fields.file"
            :src="article.fields.image.fields.file.url"
            :alt="article.fields.image.fields.title"
            :width="1472"
            :height="750"
            fit="contain"
            class="h-full max-h-[750px] min-h-[400px] w-[calc(100%+1.5rem)] max-w-none object-cover lg:w-[calc(100%+3rem)] 2xl:w-[calc((100vw-1360px)/2+100%)]"
          />
        </div>
      </header>
    </UiContainer>

    <UiContainer>
      <div class="article__body grid grid-cols-12 gap-4 py-4 lg:py-6">
        <div class="col-span-12 pt-1 lg:col-span-2">
          <div v-if="!event">
            {{ formatDate(new Date(article.fields.publishDate)) }}
          </div>

          <ArticleAuthor
            :authors="authors"
            :event="event"
          />

          <ArticleTopic
            v-if="themeOrTopics"
            :theme-or-topics="themeOrTopics"
            title="Key Topic(s)"
          />
        </div>

        <div
          class="col-span-12 lg:col-span-6"
          :class="event ? 'order-2' : ''"
        >
          <MembersOnly
            :level="article.fields.access || ['Public']"
            :show-content="true"
            :show-push="true"
          >
            <RichTextRenderer
              v-if="article.fields.content"
              :document="article.fields.content"
              :node-renderers="nodeRenderers"
            />
          </MembersOnly>

          <p
            v-if="article.fields.articleType?.fields.disclaimer"
            class="text-xs italic"
          >
            {{ article.fields.articleType?.fields.disclaimer }}
          </p>
        </div>

        <div
          v-if="event"
          class="order-1 col-span-12 mt-2 lg:order-2 lg:col-span-3 lg:col-start-10"
        >
          <LazyArticleEventInfo :event="event" />
        </div>

        <div
          v-else
          class="col-span-12 lg:col-span-3 lg:col-start-10"
        >
          <ArticleRelated
            v-if="themeOrTopics?.length"
            :article-id="article.sys.id"
            :theme-or-topic-ids="[themeOrTopics?.[0]?.sys.id ?? '']"
          />
        </div>
      </div>
    </UiContainer>

    <UiContainer
      v-if="article.fields.relatedContent"
      class="py-4 md:py-5"
    >
      <div class="flex flex-wrap gap-y-4">
        <div class="w-full">
          <TextSectionTitle>Related content</TextSectionTitle>
        </div>

        <div class="grid gap-4 lg:grid-cols-3">
          <template
            v-for="articleItem in article.fields.relatedContent"
            :key="articleItem?.sys.id"
          >
            <CardBlockArticleItem
              v-if="
                articleItem
                  && (isTypeArticle(articleItem) || isTypePage(articleItem) || isTypeReport(articleItem))
              "
              :article="articleItem"
            />
          </template>
        </div>
      </div>
    </UiContainer>
  </div>
</template>
